import _ from 'lodash'
import styled from 'styled-components'
import { map } from 'rxjs/operators'
import { Box, Text, Grid, Meter, DataTable } from 'grommet/es6'
import React, { useState, useEffect } from 'react'
import { marketDataSubject } from '../../data/marketData'

const BooksBox = styled(Box)`
  //white-space: pre;
`

const BookList = ({ data }) => (
  <DataTable
    pad={{ vertical: '0px', horizontal: '3px' }}
    sort={{
      direction: 'desc',
      property: 'price',
    }}
    columns={[
      {
        property: 'price',
        // header: <Text>Price</Text>,
        sortable: false,
      },
      {
        property: 'amount',
        // header: <Text>Amount</Text>,
      },
      {
        property: 'relativeSize',
        // header: '',
        render: (datum) => (
          <Box>
            <Meter values={[{ value: datum.relativeSize * 100 }]} thickness="small" size="small" />
          </Box>
        ),
      },
    ]}
    data={data}
  />
)

const numOrdersInBook = 10
function addPercentages(values, max) {
  return values.map((value) => ({
    ...value,
    relativeSize: value.amount / max,
  }))
}

const OrderBook = ({ ticker, exchange, data }) => {
  const [marketData, setMarketData] = useState({ bids: [], asks: [] })
  useEffect(() => {
    data
      .pipe(
        map((values) => {
          const bids = _.filter(values, { side: 'bid' }).slice(0, numOrdersInBook)
          const asks = _.filter(values, { side: 'ask' }).slice(0, numOrdersInBook)
          const max = _.max(_.map([...bids, ...asks], 'amount'))

          return {
            bids: addPercentages(bids, max),
            asks: addPercentages(asks, max),
          }
        }),
      )
      .subscribe((d) => setMarketData(d))
  }, [data])

  return (
    <Box background="light-2" pad="small">
      <Box direction="row" align="center">
        <Text size="xlarge" weight="bold">
          {ticker}
        </Text>

        <Text margin={{ left: 'small' }} size="small">
          {exchange}
        </Text>
      </Box>
      <BooksBox>
        <BookList data={marketData.asks} />
        <BookList data={marketData.bids} />
      </BooksBox>
    </Box>
  )
}

const OrderBooks = () => {
  const [marketData, setMarketData] = useState([])

  useEffect(() => {
    marketDataSubject.subscribe((d) => setMarketData(_.sortBy(d, 'ticker')))
  }, [])

  return (
    <Box fill background="light-5">
      <Grid
        columns={{
          count: 3,
          size: 'auto',
        }}
        pad="medium"
        gap="medium"
      >
        {marketData.map((val) => {
          return <OrderBook key={`orderbook${val.exchange}${val.ticker}`} {...val} />
        })}
      </Grid>
    </Box>
  )
}

export default OrderBooks
