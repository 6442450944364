import eventTracker from 'airswap.js/src/events/websocketEventTracker'
import abi from './eth2dai.json'
import constants from './constants'

const trackOasisLogNote = ({ callback, sig, guy, foo, bar, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogNote',
    params: { sig, guy, foo, bar },
    fromBlock,
    backFillBlockCount,
    topic: '0x644843f351d3fba4abcd60109eaff9f54bac8fb8ccf0bab941009c21df21cf31',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogItemUpdate = ({ callback, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogItemUpdate',
    params: {},
    fromBlock,
    backFillBlockCount,
    topic: '0xa2c251311b1a7a475913900a2a73dc9789a21b04bc737e050bbc506dd4eb3488',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogTrade = ({ callback, takerToken, makerToken, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogTrade',
    params: { takerToken, makerToken },
    fromBlock,
    backFillBlockCount,
    topic: '0x819e390338feffe95e2de57172d6faf337853dfd15c7a09a32d76f7fd2443875',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogMake = (inputs) => {
  const { callback, id, pair, makerAddress, fromBlock, backFillBlockCount, parser } = inputs
  return eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogMake',
    params: { id, pair, makerAddress },
    fromBlock,
    backFillBlockCount,
    topic: '0x773ff502687307abfa024ac9f62f9752a0d210dac2ffd9a29e38e12e2ea82c82',
    namespace: 'oasis',
    parser,
  })
}

const trackOasisLogBump = ({ callback, id, pair, makerAddress, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogBump',
    params: { id, pair, makerAddress },
    fromBlock,
    backFillBlockCount,
    topic: '0x70a14c213064359ede031fd2a1645a11ce2ec825ffe6ab5cfb5b160c3ef4d0a2',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogTake = (inputs) => {
  const { callback, pair, makerAddress, takerAddress, fromBlock, backFillBlockCount, parser } = inputs
  return eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogTake',
    params: { pair, makerAddress, takerAddress },
    fromBlock,
    backFillBlockCount,
    topic: '0x3383e3357c77fd2e3a4b30deea81179bc70a795d053d14d5b7f2f01d0fd4596f',
    namespace: 'oasis',
    parser,
  })
}

const trackOasisLogKill = ({ callback, id, pair, makerAddress, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogKill',
    params: { id, pair, makerAddress },
    fromBlock,
    backFillBlockCount,
    topic: '0x9577941d28fff863bfbee4694a6a4a56fb09e169619189d2eaa750b5b4819995',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogSetAuthority = ({ callback, authority, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogSetAuthority',
    params: { authority },
    fromBlock,
    backFillBlockCount,
    topic: '0x1abebea81bfa2637f28358c371278fb15ede7ea8dd28d2e03b112ff6d936ada4',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogSetOwner = ({ callback, owner, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogSetOwner',
    params: { owner },
    fromBlock,
    backFillBlockCount,
    topic: '0xce241d7ca1f669fee44b6fc00b8eba2df3bb514eed0f6f668f8f89096e81ed94',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogBuyEnabled = ({ callback, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogBuyEnabled',
    params: {},
    fromBlock,
    backFillBlockCount,
    topic: '0x7089e4f0bcc948f9f723a361590c32d9c2284da7ab1981b1249ad2edb9f953c1',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogMinSell = ({ callback, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogMinSell',
    params: {},
    fromBlock,
    backFillBlockCount,
    topic: '0xc28d56449b0bb31e64ee7487e061f57a2e72aea8019d810832f26dda099823d0',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogMatchingEnabled = ({ callback, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogMatchingEnabled',
    params: {},
    fromBlock,
    backFillBlockCount,
    topic: '0xea11e00ec1642be9b494019b756440e2c57dbe9e59242c4f9c64ce33fb4f41d9',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogUnsortedOffer = ({ callback, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogUnsortedOffer',
    params: {},
    fromBlock,
    backFillBlockCount,
    topic: '0x8173832a493e0a3989e521458e55bfe9feac9f9b675a94e100b9d5a85f814862',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogSortedOffer = ({ callback, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogSortedOffer',
    params: {},
    fromBlock,
    backFillBlockCount,
    topic: '0x20fb9bad86c18f7e22e8065258790d9416a7d2df8ff05f80f82c46d38b925acd',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogInsert = ({ callback, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogInsert',
    params: {},
    fromBlock,
    backFillBlockCount,
    topic: '0x6d5c16212bdea16850dce4d9fa2314c446bd30ce84700d9c36c7677c6d283940',
    namespace: 'oasis',
    parser,
  })

const trackOasisLogDelete = ({ callback, fromBlock, backFillBlockCount, parser }) =>
  eventTracker.trackEvent({
    callback,
    contract: constants.ETH2DAI_CONTRACT_ADDRESS,
    abi,
    name: 'LogDelete',
    params: {},
    fromBlock,
    backFillBlockCount,
    topic: '0xcb9d6176c6aac6478ebb9a2754cdce22a944de29ed1f2642f8613884eba4b40c',
    namespace: 'oasis',
    parser,
  })

export {
  trackOasisLogNote,
  trackOasisLogItemUpdate,
  trackOasisLogTrade,
  trackOasisLogMake,
  trackOasisLogBump,
  trackOasisLogTake,
  trackOasisLogKill,
  trackOasisLogSetAuthority,
  trackOasisLogSetOwner,
  trackOasisLogBuyEnabled,
  trackOasisLogMinSell,
  trackOasisLogMatchingEnabled,
  trackOasisLogUnsortedOffer,
  trackOasisLogSortedOffer,
  trackOasisLogInsert,
  trackOasisLogDelete,
}
