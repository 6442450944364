import _ from 'lodash'
import tokenMetadata from '../tokenMetadata'
import constants from './constants'
import { getProxyGetOffers } from './proxyContractFunctions'

function resolveBigNumber(val) {
  if (val._ethersType === 'BigNumber') {
    return val.toString()
  }
  return val
}

async function getOffers({ contractAddress, takerToken, makerToken }) {
  await tokenMetadata.ready

  return getProxyGetOffers(contractAddress, takerToken, makerToken)
    .then(([ids, takerAmounts, makerAmounts, owners, timestamps]) =>
      _.map(ids, (id, i) =>
        tokenMetadata.getReadableOrder({
          takerToken,
          makerToken,
          id: resolveBigNumber(ids[i]),
          takerAmount: resolveBigNumber(takerAmounts[i]),
          makerAmount: resolveBigNumber(makerAmounts[i]),
          makerAddress: resolveBigNumber(owners[i]),
          timestamp: resolveBigNumber(timestamps[i]),
        }),
      ),
    )
    .then((orders) => {
      return _.filter(orders, (order) => order.price)
    })
    .then((orders) =>
      orders.map(
        ({
          takerToken,
          makerToken,
          id,
          takerAmount,
          makerAmount,
          makerAddress,
          timestamp,
          takerAmountFormatted,
          makerAmountFormatted,
          takerSymbol,
          makerSymbol,
          price,
          ethAmount,
          tokenAmount,
        }) => ({
          takerToken,
          makerToken,
          id,
          takerAmount,
          makerAmount,
          makerAddress,
          timestamp,
          takerAmountFormatted,
          makerAmountFormatted,
          takerSymbol,
          makerSymbol,
          price,
          inversePrice: 1 / price,
          ethAmount,
          tokenAmount,
        }),
      ),
    )
}

async function getBestBuyOffers(limit = 50) {
  await tokenMetadata.ready
  const { DAI, WETH } = tokenMetadata.tokenAddressesBySymbol
  return (
    await getOffers({ contractAddress: constants.ETH2DAI_CONTRACT_ADDRESS, takerToken: DAI, makerToken: WETH })
  ).slice(0, limit)
}

async function getBestSellOffers(limit = 50) {
  await tokenMetadata.ready
  const { DAI, WETH } = tokenMetadata.tokenAddressesBySymbol
  return (
    await getOffers({ contractAddress: constants.ETH2DAI_CONTRACT_ADDRESS, takerToken: WETH, makerToken: DAI })
  ).slice(0, limit)
}

export { getOffers, getBestBuyOffers, getBestSellOffers }
