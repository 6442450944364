const theme = {
  global: {
    colors: {
      brand: '#313233',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
}

export default theme
