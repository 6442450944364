import { Observable, from } from 'rxjs'
import { flatMap } from 'rxjs/operators'
import { getBestBuyOffers, getBestSellOffers } from './proxyIndex'
import { trackOasisLogTake, trackOasisLogMake } from './eventListeners'

function parseOasisOrderbook(response, limit = 100) {
  const parsedBids = response.bids.slice(0, limit).map(({ inversePrice, makerAmountFormatted }) => ({
    price: inversePrice.toFixed(4),
    amount: makerAmountFormatted.toFixed(4),
    side: 'bid',
  }))
  const parsedAsks = response.asks.slice(0, limit).map(({ inversePrice, takerAmountFormatted }) => ({
    price: inversePrice.toFixed(4),
    amount: takerAmountFormatted.toFixed(4),
    side: 'ask',
  }))
  const combined = [...parsedAsks, ...parsedBids]
  return combined
}

interface ParsedOasisOrder {
  takerToken: string
  makerToken: string
  id: string
  takerAmount: string
  makerAmount: string
  makerAddress: string
  timestamp: string
  takerAmountFormatted: number
  makerAmountFormatted: number
  takerSymbol: string
  makerSymbol: string
  price: number
  inversePrice: number
}

async function getFullOasisOrderBook() {
  const response = parseOasisOrderbook({
    bids: await getBestBuyOffers(),
    asks: await getBestSellOffers(),
  })
  return response
}

const OasisMarketData = new Observable((subscription) => {
  trackOasisLogTake({
    callback: (take) => subscription.next(take),
  })
  trackOasisLogMake({
    callback: (make) => subscription.next(make),
  })
  subscription.next('initial fetch')
}).pipe(flatMap(() => from(getFullOasisOrderBook())))

export { OasisMarketData }
