import { Box, Layer, Text, Button } from 'grommet'
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import SearchableList from './SearchableList'
import { availableWithoutActiveMarketsSubject, activeMarketsSubject, addMarket, removeMarket } from '../data/markets'

const MarketsSelector = () => {
  const location = useLocation()
  const history = useHistory()
  const [availableMarkets, setAvailableMarkets] = useState([])
  const [activeMarkets, setActiveMarkets] = useState([])

  useEffect(() => {
    // Update the document title using the browser API
    availableWithoutActiveMarketsSubject.subscribe((availableMarketsObj) =>
      setAvailableMarkets(_.values(availableMarketsObj)),
    )
    activeMarketsSubject.subscribe((activeMarketsArray) => setActiveMarkets(activeMarketsArray))
  }, [])

  const show = _.get(location, 'state.showSidebar', false)
  const hide = () => history.push(location.pathname, { showSidebar: false })

  return (
    show && (
      <Layer margin="medium" onEsc={() => hide()} onClickOutside={() => hide()}>
        <Box flex width="large" direction="row" background="light-2" elevation="small" align="stretch" justify="around">
          <SearchableList
            title="Active Markets"
            columns={[
              {
                property: 'ticker',
                header: <Text>Ticker</Text>,
              },
              {
                property: 'exchange',
                header: 'Exchange',
              },
              {
                property: 'id',
                header: 'Action',
                primary: true,
                render: ({ id }) => <Button label="Remove" gap="xsmall" onClick={() => removeMarket(id)} />,
              },
            ]}
            data={_.values(activeMarkets)}
          />
          <SearchableList
            title="Available Markets"
            columns={[
              {
                property: 'ticker',
                header: <Text>Ticker</Text>,
              },
              {
                property: 'exchange',
                header: 'Exchange',
              },
              {
                property: 'id',
                header: 'Action',
                primary: true,
                render: ({ id }) => <Button label="Add" gap="xsmall" onClick={() => addMarket(id)} />,
              },
            ]}
            data={availableMarkets}
          />
        </Box>
      </Layer>
    )
  )
}

export default MarketsSelector
