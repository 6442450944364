import { Box, Button, Heading } from 'grommet'
import _ from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import React from 'react'
import { Database } from 'grommet-icons'

const Header = (props) => {
  const history = useHistory()
  const location = useLocation()
  return (
    <Box
      tag="header"
      direction="row"
      align="center"
      justify="between"
      background="brand"
      pad={{ left: 'medium', right: 'small', vertical: 'medium' }}
      elevation="medium"
      style={{ zIndex: '1' }}
      {...props}
    >
      <Heading level="3" margin="none">
        Kiss Capital Markets
      </Heading>
      <Button
        icon={<Database />}
        onClick={() => history.push(location.pathname, { showSidebar: !_.get(location, 'state.showSidebar') })}
        label="Data Sources"
        primary
      />
    </Box>
  )
}

export default Header
