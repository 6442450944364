import fetch from 'isomorphic-fetch'
import BigNumber from 'bignumber.js'
import _ from 'lodash'
import { NETWORK, ETH_ADDRESS, WETH_CONTRACT_ADDRESS, DAI_CONTRACT_ADDRESS } from 'airswap.js/src/constants'

const TOKEN_METADATA_BASE_URL = 'https://token-metadata.airswap.io'
const OPENSEA_API_URL = NETWORK === 4 ? 'https://rinkeby-api.opensea.io/api/v1' : 'https://api.opensea.io/api/v1'
const MAX_DISPLAY_DECIMALS = 8
const makeCrawlTokenUrl = (address, forceAirswapUIApproved) =>
  `${TOKEN_METADATA_BASE_URL}/crawlTokenData?address=${address}${NETWORK === 4 ? '&test=true' : ''}${
    forceAirswapUIApproved ? '&forceAirswapUIApproved=true' : ''
  }`
const makeCrawlNFTItemUrl = (address, id) => `${OPENSEA_API_URL}/asset/${address}/${id}`

BigNumber.config({ ERRORS: false })
BigNumber.config({ EXPONENTIAL_AT: 1e9 }) //eslint-disable-line

const BASE_ASSET_TOKEN_ADDRESSES = [
  ETH_ADDRESS,
  WETH_CONTRACT_ADDRESS,
  DAI_CONTRACT_ADDRESS,
  '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359', // 'SAI'
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // 'USDC'
  '0x0000000000085d4780b73119b644ae5ecd22b376', // 'TUSD'
  '0x00000100f2a2bd000715001920eb70d229700085', // 'TCAD'
  '0x00000000441378008ea67f4284a57932b1c000a5', // 'TGBP'
  '0x0000852600ceb001e08e00bc008be620d60031f2', // 'THKD'
  '0x00006100f7090010005f1bd7ae6122c3c2cf0090', // 'TAUD'
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599', // 'WBTC'
  '0xdac17f958d2ee523a2206206994597c13d831ec7', // 'USDT'
]

function crawlToken(tokenAddress, forceUIApproval) {
  return new Promise((resolve, reject) => {
    fetch(makeCrawlTokenUrl(tokenAddress, forceUIApproval), {
      method: 'get',
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          reject(response.statusText)
        }
        return response.json()
      })
      .then(resolve)
  })
}

function crawlNFTItem(tokenAddress, tokenId) {
  return new Promise((resolve, reject) => {
    fetch(makeCrawlNFTItemUrl(tokenAddress, tokenId), {
      method: 'get',
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          reject(response.statusText)
        }
        return response.json()
      })
      .then(resolve)
  })
}

function parseAmount(amount, precision) {
  const num = new BigNumber(Math.max(0, Number(amount)))
  return Number(num.toFixed(precision, BigNumber.ROUND_FLOOR))
}

class TokenMetadata {
  constructor() {
    this.tokens = []
    this.setTokens([
      {
        symbol: 'ETH',
        decimals: '18',
        address: '0x0000000000000000000000000000000000000000',
        name: 'Ethereum',
      },
      {
        symbol: 'WETH',
        address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        name: 'WETH',
        decimals: '18',
      },
      {
        symbol: 'DAI',
        address: '0x6b175474e89094c44da98b954eedeac495271d0f',
        name: 'Multi Collateral DAI',
        decimals: '18',
      },
      {
        symbol: 'USDC',
        address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        name: 'USD Coin',
        decimals: '6',
      },
      {
        symbol: 'USD',
        address: '0xffffffffffffffffffffffffffffffffffffffff',
        name: 'USD (fiat)',
        decimals: '6',
      },
      {
        symbol: 'REP',
        address: '0x1985365e9f78359a9b6ad760e32412f4a445e862',
        name: 'Augur',
        decimals: '18',
      },
      {
        symbol: 'MKR',
        address: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
        name: 'Maker',
        decimals: '18',
      },
      {
        symbol: 'SAI',
        address: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
        name: 'Single Collateral Dai',
        decimals: '18',
      },
    ])
    this.nftItems = []
    this.ready = new Promise((resolve) => resolve(true))
  }

  setTokens(tokens) {
    this.tokens = _.uniqBy([...this.tokens, ...tokens], (t) => t.symbol)
    this.airswapUITokens = _.filter(tokens, { airswapUI: 'yes' })
    this.tokensByAddress = _.keyBy(tokens, 'address')
    this.tokenSymbolsByAddress = _.mapValues(this.tokensByAddress, (t) => t.symbol)
    this.tokenAddresses = _.map(this.tokens, (t) => t.address)
    this.tokensBySymbol = _.keyBy(tokens, 'symbol')
    this.tokenAddressesBySymbol = _.mapValues(this.tokensBySymbol, (t) => t.address)
    return tokens
  }

  crawlToken(address, forceUIApproval = false) {
    return crawlToken(address, forceUIApproval).then((token) => {
      this.tokens.push(token)
      return token
    })
  }

  async crawlNFTItem(address, id) {
    const nftItem = await crawlNFTItem(address, id).then((token) => ({
      name: token.asset_contract.name,
      symbol: token.asset_contract.symbol,
      address: token.asset_contract.address,
      id: token.token_id,
      kind: 'ERC721',
      img_url: token.image_url,
    }))
    this.nftItems.push(nftItem)
    return nftItem
  }

  formatSignificantDigitsByToken(tokenQuery, value) {
    const token = _.find(this.tokens, tokenQuery)

    if (!token) {
      throw new Error('token not in metadata, crawl before for next retry')
    }
    const { decimals } = token
    return parseAmount(value, Math.min(Number(decimals), MAX_DISPLAY_DECIMALS))
  }

  formatAtomicValueByToken(tokenQuery, value) {
    const token = _.find(this.tokens, tokenQuery)
    if (!token) {
      throw new Error('token not in metadata, crawl before for next retry')
    }

    const { decimals } = token
    const power = 10 ** Number(decimals)
    return new BigNumber(value).times(power).toFixed(0)
  }

  formatFullValueByToken(tokenQuery, value) {
    const token = _.find(this.tokens, tokenQuery)
    if (!token) {
      throw new Error('token not in metadata, crawl before for next retry')
    }
    const { decimals } = token
    const power = 10 ** Number(decimals)
    return new BigNumber(value).div(power).toString()
  }

  formatDisplayValueByToken(tokenQuery, value) {
    return this.formatSignificantDigitsByToken(tokenQuery, this.formatFullValueByToken(tokenQuery, value))
  }

  isBaseAsset(token, pair) {
    const otherToken = _.first(_.without(pair, token))
    if (!otherToken) {
      throw new Error('invalid pair')
    } else if (!_.includes(pair, token)) {
      throw new Error('token not in pair')
    }
    const { ETH, WETH, DAI } = this.tokenAddressesBySymbol
    const baseAssets = [ETH, WETH, DAI]
    if (_.includes(baseAssets, token) && !_.includes(baseAssets, otherToken)) {
      return true
    }
    if (!_.includes(baseAssets, token) && _.includes(baseAssets, otherToken)) {
      return false
    }
    if (_.includes(baseAssets, token) && _.includes(baseAssets, otherToken)) {
      if (baseAssets.indexOf(token) === baseAssets.indexOf(otherToken)) {
        throw new Error('tokens cannot be the same')
      } else if (baseAssets.indexOf(token) < baseAssets.indexOf(otherToken)) {
        return true
      } else if (baseAssets.indexOf(token) > baseAssets.indexOf(otherToken)) {
        return false
      }
    } else if (!_.includes(baseAssets, token) && !_.includes(baseAssets, otherToken)) {
      const first = _.first(_.sortBy(pair))
      if (token === first) {
        return true
      }
      return false
    }
  }

  getReadableOrder(order, tokenSymbolsByAddressParam, formatFullValueByTokenParam, parseValueByTokenParam) {
    const parseByToken = parseValueByTokenParam || this.formatSignificantDigitsByToken.bind(this)
    const tokenSymbolsByAddress = tokenSymbolsByAddressParam || this.tokenSymbolsByAddress
    const { makerAddress, makerToken, takerAddress, takerToken, expiration, nonce } = order
    let { takerAmount, makerAmount, takerAmountFormatted, makerAmountFormatted } = order

    if (takerAmount && makerAmount) {
      takerAmountFormatted = Number(this.formatFullValueByToken({ address: takerToken }, takerAmount))
      makerAmountFormatted = Number(this.formatFullValueByToken({ address: makerToken }, makerAmount))
    } else if (takerAmountFormatted && makerAmountFormatted) {
      takerAmount = this.formatAtomicValueByToken({ address: takerToken }, takerAmountFormatted)
      makerAmount = this.formatAtomicValueByToken({ address: makerToken }, makerAmountFormatted)
      takerAmountFormatted = Number(takerAmountFormatted)
      makerAmountFormatted = Number(makerAmountFormatted)
    }

    const takerSymbol = tokenSymbolsByAddress[takerToken]
    const makerSymbol = tokenSymbolsByAddress[makerToken]

    let ethAmountFull = 0

    let tokenAmountFull = 0

    let baseTokenAmountFull = 0

    let price

    if (takerSymbol === 'ETH' || takerSymbol === 'WETH') {
      ethAmountFull = takerAmountFormatted
      tokenAmountFull = makerAmountFormatted
    } else if (makerSymbol === 'ETH' || makerSymbol === 'WETH') {
      ethAmountFull = makerAmountFormatted
      tokenAmountFull = takerAmountFormatted
    } else if (BASE_ASSET_TOKEN_ADDRESSES.includes(takerToken)) {
      baseTokenAmountFull = takerAmountFormatted
      tokenAmountFull = makerAmountFormatted
    } else if (BASE_ASSET_TOKEN_ADDRESSES.includes(makerToken)) {
      baseTokenAmountFull = makerAmountFormatted
      tokenAmountFull = takerAmountFormatted
    }

    // if eth/weth is involved, set price in eth terms
    // otherwise set price in base token terms
    if (takerSymbol === 'ETH' || takerSymbol === 'WETH' || makerSymbol === 'ETH' || makerSymbol === 'WETH') {
      price = parseByToken({ symbol: 'ETH' }, new BigNumber(ethAmountFull).div(tokenAmountFull).toString())
    } else if (BASE_ASSET_TOKEN_ADDRESSES.includes(takerToken)) {
      price = parseByToken({ symbol: takerSymbol }, new BigNumber(baseTokenAmountFull).div(tokenAmountFull).toString())
    } else if (BASE_ASSET_TOKEN_ADDRESSES.includes(makerToken)) {
      price = parseByToken({ symbol: makerSymbol }, new BigNumber(baseTokenAmountFull).div(tokenAmountFull).toString())
    }

    const makerPrice = new BigNumber(makerAmountFormatted).div(takerAmountFormatted).toNumber()
    const takerPrice = new BigNumber(takerAmountFormatted).div(makerAmountFormatted).toNumber()

    return _.pickBy(
      {
        ...order,
        takerAmountFormatted,
        makerAmountFormatted,
        takerSymbol,
        makerSymbol,
        makerAddress,
        makerToken,
        takerAddress,
        takerToken,
        makerAmount,
        takerAmount,
        expiration,
        nonce,
        makerPrice, // multiply by taker amount to get maker amount
        takerPrice, // multiply by maker amount to get taker amount
        // ethAmount,
        price,
        inversePrice: 1 / price,
        // tokenSymbol,
        // tokenAmount,
        // tokenAddress,
        // baseTokenAmount,
        // baseTokenSymbol,
      },
      _.indentity,
    )
  }
}

const tm = new TokenMetadata()

export default tm
