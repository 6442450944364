import React from 'react'
import styled from 'styled-components'
import { Grommet, Box } from 'grommet'
import { BrowserRouter as Router } from 'react-router-dom'
import theme from './theme'
import Header from './components/Header'
import MarketsSelector from './components/MarketsSelector'
import OrderBooks from './components/OrderBooks/OrderBookList'

const Container = styled(Box)`
  min-height: 100vh;
`

function App({ auth, authError }) {
  let content
  if (auth === 'authenticating') {
    content = (
      <Container fill>
        <Box flex align="center" justify="center">
          Signing in with your Google Account credentials
        </Box>
      </Container>
    )
  }
  if (auth === 'authenticated') {
    content = (
      <Container fill background="light-5">
        <Header />
        <Box direction="row" flex overflow={{ horizontal: 'hidden' }}>
          <OrderBooks />
          <MarketsSelector />
        </Box>
      </Container>
    )
  }
  if (auth === 'error') {
    content = (
      <Container fill>
        <Box flex align="center" justify="center">
          {authError}
          <br />
        </Box>
      </Container>
    )
  }

  return (
    <Router>
      <Grommet theme={theme}>{content}</Grommet>
    </Router>
  )
}

export default App
