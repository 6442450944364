import _ from 'lodash'
import { fromRef, ListenEvent } from 'rxfire/database'
import { Subject, combineLatest } from 'rxjs'
import firebase from '../firebase'

const db = firebase.database()

const activeMarketsRef = db.ref('/activeMarkets')
const availableMarketsRef = db.ref('/availableMarkets')

export const addMarket = (id) => activeMarketsRef.child(id).set(true)

export const removeMarket = (id) => activeMarketsRef.child(id).remove()

const availableMarketsSubject = new Subject<AvailableMarket[]>()
fromRef(availableMarketsRef, ListenEvent.value).subscribe((change) => {
  availableMarketsSubject.next(change.snapshot.val())
})

const activeMarketsKeysSubject = new Subject<string[]>()
fromRef(activeMarketsRef, ListenEvent.value).subscribe((change) => {
  activeMarketsKeysSubject.next(Object.keys(change.snapshot.val() || {}))
})

export interface Market {
  dataSource: string
  exchange: string
  id: string
  ticker: string
}

export interface AvailableMarket {
  [key: string]: Market
}

const activeMarketsSubject = new Subject<Market[]>()
combineLatest(availableMarketsSubject, activeMarketsKeysSubject).subscribe(
  ([availableMarkets, activeMarketsKeys]: [AvailableMarket[], string[]]) => {
    const activeMarkets = activeMarketsKeys.map((key) => availableMarkets[key])
    activeMarketsSubject.next(activeMarkets)
  },
)

const activeCCXTExchangesSubject = new Subject<string[]>()
activeMarketsSubject.subscribe((activeMarkets) => {
  const uniqueMarkets = _.uniq(_.map(_.filter(activeMarkets, { dataSource: 'ccxt' }), 'exchange'))
  activeCCXTExchangesSubject.next(uniqueMarkets)
})

const availableWithoutActiveMarketsSubject = new Subject()
combineLatest(availableMarketsSubject, activeMarketsKeysSubject).subscribe(([availableMarkets, activeMarketsKeys]) => {
  const filteredMarkets = _.pickBy(availableMarkets, (val, key) => !_.includes(activeMarketsKeys, key))
  availableWithoutActiveMarketsSubject.next(filteredMarkets)
})

export {
  availableMarketsSubject,
  activeMarketsSubject,
  availableWithoutActiveMarketsSubject,
  activeCCXTExchangesSubject,
}
