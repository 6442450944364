import React from 'react'
import _ from 'lodash'
import firebase from './firebase'
import ReactDOM from 'react-dom'
import App from './App'

const authorizedUsers = ['sam@kiss.capital', 'maxime@kiss.capital']

class Auth extends React.Component {
  state = {
    auth: 'authenticating',
  }

  async componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.validateUserAuthorization(user)
      } else {
        const provider = new firebase.auth.GoogleAuthProvider()

        firebase
          .auth()
          .signInWithPopup(provider)
          .then((result) => {
            this.validateUserAuthorization(result.user)
          })
          .catch((error) => {
            this.setState({
              auth: 'error',
              authError: error.message,
            })
          })
      }
    })
  }

  validateUserAuthorization(user) {
    if (_.includes(authorizedUsers, user.email)) {
      this.setState({
        auth: 'authenticated',
      })
    } else {
      firebase.auth().signOut()
      this.setState({
        auth: 'error',
        authError: `User ${user.email} not authorized to use kiss.capital admin`,
      })
    }
  }

  render() {
    const { auth, authError } = this.state
    return <App auth={auth} authError={authError} />
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Auth />
  </React.StrictMode>,
  document.getElementById('root'),
)
