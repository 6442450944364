import * as ethers from 'ethers'
import abi from './eth2daiProxy.json'
import constants from './constants'

const httpProvider = ethers.getDefaultProvider()

function getProxyContract(provider) {
  return new ethers.Contract(constants.ETH2DAI_PROXY_CONTRACT_ADDRESS, abi, provider)
}
function getProxyGetOffers(contractAddress, takerToken, makerToken) {
  const contract = getProxyContract(httpProvider)
  return contract.getOffers(contractAddress, takerToken, makerToken)
}

function getProxyGetOffersAmountToBuyAll(contractAddress, makerToken, buyAmt, takerToken) {
  const contract = getProxyContract(httpProvider)
  return contract.getOffersAmountToBuyAll(contractAddress, makerToken, buyAmt, takerToken)
}

function getProxyGetOffer(contractAddress, offerId) {
  const contract = getProxyContract(httpProvider)
  return contract.getOffer(contractAddress, offerId)
}

function getProxyGetOffersAmountToSellAll(contractAddress, takerToken, payAmt, makerToken) {
  const contract = getProxyContract(httpProvider)
  return contract.getOffersAmountToSellAll(contractAddress, takerToken, payAmt, makerToken)
}

export { getProxyGetOffers, getProxyGetOffersAmountToBuyAll, getProxyGetOffer, getProxyGetOffersAmountToSellAll }
