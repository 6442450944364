import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyCjtadJ3LykQYo103QEVp6ewS7tCscxsCk',
  authDomain: 'kiss-capital-272414.firebaseapp.com',
  databaseURL: 'https://kiss-capital-272414.firebaseio.com',
  projectId: 'kiss-capital-272414',
  storageBucket: 'kiss-capital-272414.appspot.com',
  messagingSenderId: '1058659630500',
  appId: '1:1058659630500:web:9886cce278bd6a2fe11531',
}

firebase.initializeApp(firebaseConfig)

export default firebase
