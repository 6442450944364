import _ from 'lodash'
import React, { useState } from 'react'
import { Box, DataTable, Heading, TextInput } from 'grommet'
import styled from 'styled-components'

const ListContainer = styled(Box)`
  overflow: scroll;
`

const Container = styled(Box)`
  flex: 1;
`

const SearchableList = ({ title, columns, data }) => {
  const [searchValue, setSearchValue] = useState('')

  const filteredData = searchValue
    ? data.filter((datum) => {
        const matchedParams = Object.values(_.omit(datum, 'id')).filter((param: String) => {
          return param.toLowerCase().includes(searchValue.toLowerCase())
        })

        return !!matchedParams.length
      })
    : data

  return (
    <Container margin="xsmall">
      <Heading size="small" margin="none">
        {title}
      </Heading>
      <TextInput
        placeholder={`Search ${title}`}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
      />
      <ListContainer>
        <DataTable columns={columns} data={filteredData} />
      </ListContainer>
    </Container>
  )
}

export default SearchableList
