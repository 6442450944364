import _ from 'lodash'
import { timer, from, combineLatest, Subject } from 'rxjs'
import { map, flatMap } from 'rxjs/operators'

import ccxt, { Exchange, OrderBook } from 'ccxt'
import { activeMarketsSubject, activeCCXTExchangesSubject, Market } from './markets'
import { OasisMarketData } from './oasis/marketData'

interface CCXTInstances {
  [key: string]: Exchange
}

const ccxtInstances: CCXTInstances = {}

function createCcxtExchangeInstance(name: string): Exchange {
  const exchange = new ccxt[name]({
    enableRateLimit: true,
  })
  exchange.proxy = 'https://serene-springs-63129.herokuapp.com/'
  return exchange
}

interface ParsedOrder {
  price: number
  amount: number
  side: string
}

type ParsedOrderBook = Array<ParsedOrder>

function parseCCXTOrderbook(response: OrderBook, limit = 100): ParsedOrderBook {
  const parsedBids = response.bids.slice(0, limit).map(([price, amount]) => ({ price, amount, side: 'bid' }))
  const parsedAsks = response.asks.slice(0, limit).map(([price, amount]) => ({ price, amount, side: 'ask' }))
  const combined = [...parsedAsks, ...parsedBids]
  return combined
}

export const ccxtObservable = activeCCXTExchangesSubject.pipe(
  map((exchangeNames) => {
    exchangeNames.forEach((exchangeName) => {
      if (!ccxtInstances[exchangeName]) {
        ccxtInstances[exchangeName] = createCcxtExchangeInstance(exchangeName)
      }
    })
    return ccxtInstances
  }),
)

type CCXTSubject = Subject<ParsedOrderBook>

const marketDataObservers = {}
const marketDataSubjects: { [key: string]: CCXTSubject } = {}

function makeCCXTPollingSubject(exchangeInstance: Exchange, market: Market): CCXTSubject {
  if (marketDataSubjects[market.id]) {
    return marketDataSubjects[market.id]
  }

  const { ticker } = market
  marketDataSubjects[market.id] = new Subject<ParsedOrderBook>()
  marketDataObservers[market.id] = timer(0, 3000)
    .pipe(
      flatMap(() => {
        return from(exchangeInstance.fetchOrderBook(ticker).then(parseCCXTOrderbook))
      }),
    )
    .subscribe((data) => {
      marketDataSubjects[market.id].next(data)
    })
  return marketDataSubjects[market.id]
}

function makeOasisSubject(market) {
  if (marketDataSubjects[market.id]) {
    return marketDataSubjects[market.id]
  }

  marketDataSubjects[market.id] = new Subject<ParsedOrderBook>()
  OasisMarketData.subscribe((data) => {
    marketDataSubjects[market.id].next(data)
  })
  return marketDataSubjects[market.id]
}

interface DataMarket extends Market {
  data: CCXTSubject
}

const marketDataSubjectCreators = {
  ccxt: (activeMarket) => makeCCXTPollingSubject(ccxtInstances[activeMarket.exchange], activeMarket),
  oasis: (activeMarket) => makeOasisSubject(activeMarket),
}

function addMissingMarketSubscriptions([ccxts, activeMarkets]: [CCXTInstances, Market[]]): DataMarket[] {
  const existingMarketDataIds = _.keys(marketDataSubjects)
  const activeMarketIds = activeMarkets.map((m) => m.id)
  const marketIdsToRemove = _.difference(existingMarketDataIds, activeMarketIds)

  marketIdsToRemove.forEach((id) => {
    marketDataSubjects[id].unsubscribe()
    marketDataObservers[id].unsubscribe()
    delete marketDataSubjects[id]
    delete marketDataObservers[id]
  })

  return activeMarkets.map((activeMarket) => ({
    ...activeMarket,
    data: marketDataSubjectCreators[activeMarket.dataSource](activeMarket),
  }))
}

export const marketDataSubject = combineLatest(ccxtObservable, activeMarketsSubject).pipe(
  map(addMissingMarketSubscriptions),
)
